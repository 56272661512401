<template>
  <div class="classCategoryRebort">
    <div class="search" style="display: block">
      <a-tree-select
        class="search-style"
        style="width: 300px"
        v-model="OrgIds"
        :tree-data="treeData"
        tree-checkable
        :maxTagCount="1"
        placeholder="请选择搜索范围"
        @change="change"
        :show-checked-strategy="SHOW_PARENT"
      />
      <!--:show-checked-strategy="SHOW_PARENT"-->
      <a-range-picker
        class="search-style"
        style="width: 200px"
        format="YYYY-MM-DD"
        :placeholder="['开始时间', '结束时间']"
        allowClear
        @change="ChangeSearchTime"
      />
      <a-button class="search-style" @click="_clear">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
    </div>
    <div :style="'width:' + pageWidth + 'px'" v-if="data">
      <a-table
        bordered
        :pagination="false"
        v-if="data"
        :columns="columns"
        :data-source="data"
        :scroll="{ y: 500, x: 3850 }"
      >
        <div slot="key" slot-scope="text, record, index">
          <a-icon
            style="font-size: 18px;margin-right: 8px;color: #3aa1ff;"
            @click="_source(text, record)"
            type="pie-chart"
          />
          <a-icon
            style="font-size: 18px;color: #8a7bd4;"
            type="line-chart"
            @click="_container(text, record)"
          />
        </div>
      </a-table>
    </div>
    <p class="tips">注释:业绩均为毛业绩</p>
    <div v-show="spinning" style="position:fixed;left:50%;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px;color:red;"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
export default {
  name: 'classCategoryRebort',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),

      columns: [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 240
        },
        {
          title: '总计',
          children: [
            {
              title: '总业绩',
              dataIndex: 'Cmmoney',
              key: 'Cmmoney',
              align: 'center',
              width: 120
            },
            {
              title: '总单数',
              dataIndex: 'CmmoneyCount',
              key: 'CmmoneyCount',
              width: 120
            }
          ]
        },

        {
          title: 'A1',
          children: [
            {
              title: '业绩',
              dataIndex: 'A1Money',
              key: 'A1Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'A1Count',
              key: 'A1Count',
              width: 120
            }
          ]
        },

        {
          title: 'A2',
          children: [
            {
              title: '业绩',
              dataIndex: 'A2Money',
              key: 'A2Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'A2Count',
              key: 'A2Count',
              width: 120
            }
          ]
        },

        {
          title: 'B1',
          children: [
            {
              title: '业绩',
              dataIndex: 'B1Money',
              key: 'B1Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'B2Count',
              key: 'B2Count',
              width: 120
            }
          ]
        },
        {
          title: '48课包',
          children: [
            {
              title: '业绩',
              dataIndex: 'Commonly48Money',
              key: 'Commonly48Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'Commonly48Count',
              key: 'Commonly48Count',
              width: 120
            }
          ]
        },
        {
          title: '96课包',
          children: [
            {
              title: '业绩',
              dataIndex: 'Commonly96Money',
              key: 'Commonly96Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'Commonly96Count',
              key: 'Commonly96Count',
              width: 120
            }
          ]
        },

        {
          title: '144课包',
          children: [
            {
              title: '业绩',
              dataIndex: 'Commonly144Money',
              key: 'Commonly144Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'Commonly144Count',
              key: 'Commonly144Count',
              width: 120
            }
          ]
        },

        {
          title: '专项课A',
          children: [
            {
              title: '业绩',
              dataIndex: 'SpecialCoursesAMoney',
              key: 'SpecialCoursesAMoney',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'SpecialCoursesACount',
              key: 'SpecialCoursesACount',
              width: 120
            }
          ]
        },

        {
          title: '专项课B',
          children: [
            {
              title: '业绩',
              dataIndex: 'SpecialCoursesBMoney',
              key: 'SpecialCoursesBMoney',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'SpecialCoursesBCount',
              key: 'SpecialCoursesBCount',
              width: 120
            }
          ]
        },

        {
          title: 'VIPA1',
          children: [
            {
              title: '业绩',
              dataIndex: 'VIPA1Money',
              key: 'VIPA1Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'VIPA1Count',
              key: 'VIPA1Count',
              width: 120
            }
          ]
        },

        {
          title: 'VIPA2',
          children: [
            {
              title: '业绩',
              dataIndex: 'VIPA2Money',
              key: 'VIPA2Money',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'VIPA2Count',
              key: 'VIPA2Count',
              width: 120
            }
          ]
        },
        {
          title: '其它',
          children: [
            {
              title: '业绩',
              dataIndex: 'OtherMoney',
              key: 'OtherMoney',
              align: 'center',
              width: 120
            },
            {
              title: '单数',
              dataIndex: 'OtherCount',
              key: 'OtherCount',
              width: 120
            }
          ]
        }
      ],
      data: '',
      startSignDate: '',
      endSignDate: '',
      spinning: false,
      treeData: [
        {
          title: 'Node1',
          value: '7520669f3b20008c6a6df71e9cebc2f9',
          key: '3',
          children: [
            {
              title: 'Child Node1',
              value: '7520669f3b20008c6a6df71e9cebc2f944',
              key: '4'
            }
          ]
        },
        {
          title: 'Node2',
          value: '6',
          key: '7',
          children: [
            {
              title: 'Child Node3',
              value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
              key: '8'
            },
            {
              title: 'Child Node4',
              value: '7520669f3b20008c6a6df71e9cebc2f9wee',
              key: '9'
            },
            {
              title: 'Child Node5',
              value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
              key: '1'
            }
          ]
        }
      ],

      OrgIds: [],
      OrgName: [],
      ParentId: [],
      SHOW_PARENT: 'SHOW_ALL',
      pageWidth: ''
    }
  },
  created () {
    this.pageWidth = document.documentElement.clientWidth - 440
    this._treeData()
    this.loadData()
  },
  methods: {
    _treeData () {
      let self = this
      this.$axios.get(
        1090,
        {
          UserId: self.userinfo.uid
        },
        res => {
          self.treeData = res.data
        }
      )
    },
    change (value, label, text) {
      if (text.checked) {
        this.ParentId.push(text.triggerValue)
      } else {
        if (this.ParentId.length > 0) {
          for (var i = 0; i < this.ParentId.length; i++) {
            if (text.triggerValue == this.ParentId[i]) {
              this.ParentId.splice(i, 1)
            }
          }
        }
      }

      this.OrgName = label
      this.OrgIds = value
    },
    loadData () {
      this.spinning = true
      let self = this
      this.$axios.post(
        1235,
        {
          startSignDate: self.startSignDate,
          endSignDate: self.endSignDate,
          OrgIds: JSON.stringify(self.OrgIds),
          UserId: self.userinfo.uid,
          OrgName: JSON.stringify(self.OrgName),
          ParentId: JSON.stringify(self.ParentId)
        },
        res => {
          self.spinning = false

          self.data = res.data
          console.log(self.data)
        }
      )
    },
    _search () {
      this.loadData()
    },
    _clear () {
      this.OrgIds = []
      this.OrgName = []
      this.startSignDate = ''
      this.endSignDate = ''
      this.ParentId = []
      this.loadData()
    },
    ChangeSearchTime (time, timeString) {
      this.startSignDate = timeString[0]
      this.endSignDate = timeString[1]
    }
  }
}
</script>

<style scoped lang="less">
.classCategoryRebort {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}

.search {
  margin-bottom: 20px;
  .search-style {
    display: inline-block;
    width: 120px;
    margin-right: 15px;
  }
}
.tips {
  color: red;
  margin-top: 25px;
}
</style>
